import React from 'react';
import Button, {LinkButton, RouterLinkButton} from '../../../components/button/button';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import {useLocation} from 'react-router-dom';
import {TELEGRAM_SUPPORT} from '../../../constants';
import MessageQuestionSquareIcon from '../../../assets/icons0/message-question-square.svg';
import CreditCard02Icon from '../../../assets/icons0/credit-card-02.svg';
import ShoppingBag03Icon from '../../../assets/icons0/shopping-bag-03.svg';
import ArrowBlockDownIcon from '../../../assets/icons0/arrow-block-down.svg';
import CalendarIcon from '../../../assets/icons0/calendar.svg';

export const TicketingCompleteActionSuccess = React.memo(({downloadSuccessfullImpulseTickets, showWalletButtonSuccess}) => {
    const location = useLocation();
    
    return (
        <div className='checkout-complete__actions display-flex flex-direction-column mw768-justify-content-center row-gap-0dot75rem'>
            {showWalletButtonSuccess &&
                <RouterLinkButton {...{color: 'primary-gradient', size: 'lg', fluid: 'always', to: `/cabinet${searchFilter(location.search)}`}} >
                    <ShoppingBag03Icon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                    <FormattedMessage defaultMessage='To wallet'/>
                </RouterLinkButton>
            }
            <div className='display-flex flex-direction-column-row row-gap-0dot75rem column-gap-0dot75rem justify-content-center'>
                <Button {...{color: 'primary-inverse', size: 'lg', fluid: 'always', clickHandler: downloadSuccessfullImpulseTickets}} >
                    <ArrowBlockDownIcon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                    <FormattedMessage defaultMessage='Download ticket'/>
                </Button>
                {/* <Button {...{color: 'primary-inverse', size: 'lg', fluid: 'always'}}>
                    <CalendarIcon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                    <FormattedMessage defaultMessage='Add to calendar'/>
                </Button> */}
            </div>
            <RouterLinkButton {...{color: 'secondary-gray', size: 'lg', fluid: 'always', to: `/${searchFilter(location.search)}`}} >
                <FormattedMessage defaultMessage='Return to main page'/>
            </RouterLinkButton>
        </div>
    )
});

export const TicketingCompleteActionError = React.memo(({eventLink}) => {
    const location = useLocation();
    
    return (
        <div className='checkout-complete__actions checkout-complete__actions--error display-flex flex-direction-column mw768-justify-content-center row-gap-0dot75rem'>
            <div className='display-flex flex-direction-column-row row-gap-0dot75rem column-gap-0dot75rem justify-content-center'>
                {/* <RouterLinkButton {...{color: 'primary-inverse', size: 'lg', fluid: 'always', to: `/cabinet${searchFilter(location.search)}`}} >
                    <CreditCard02Icon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                    <FormattedMessage defaultMessage='Choose another payment method'/>
                </RouterLinkButton> */}
                <LinkButton {...{color: 'primary-inverse', size: 'lg', fluid: 'always', link: {href: TELEGRAM_SUPPORT, target: 'blank'}}}>
                    <MessageQuestionSquareIcon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                    <FormattedMessage defaultMessage='Contact support'/>
                </LinkButton>
            </div>
            <RouterLinkButton {...{color: 'secondary-gray', size: 'lg', fluid: 'always', to: `${eventLink}${searchFilter(location.search)}`}} >
                <FormattedMessage defaultMessage='Return to event'/>
            </RouterLinkButton>
        </div>
    )
});

const CompleteAction = () => {
    const location = useLocation();
    
    return (
        <div className='display-flex mw768-justify-content-center'>
            <div className='mxw767-flex-1 margin-right-0dot75rem'>
                <RouterLinkButton {...{color: 'secondary-gray', size: 'xl', mw768Size: 'xl-wide', fluid: 'mobile', to: `/${searchFilter(location.search)}`}} >
                    <FormattedMessage defaultMessage='To main page'/>
                </RouterLinkButton>
            </div>
            <div className='mxw767-flex-1'>
                <RouterLinkButton {...{color: 'primary-gradient', size: 'xl', mw768Size: 'xl-wide', fluid: 'mobile', to: `/cabinet${searchFilter(location.search)}`}} >
                    <FormattedMessage defaultMessage='To wallet'/>
                </RouterLinkButton>
            </div>
        </div>
    )
};

export default React.memo(CompleteAction);