import React from 'react';
import './seat-map.scss';
import classNames from 'classnames';
import SEAT_MAPS from './seats';
import getTagValue from '../../../utilities/get-tag-value';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import useWindowDimensions from '../../../useWindowDimentions';
import Expand03Icon from '../../../assets/icons0/expand-03.svg';

const Seat = React.memo(({endpoint, priceCategories, localCartState, x, y, rx, transform, width, height, svgTag = 'rect', d, allowMultipleBuy}) => {
    const colorClass = (!endpoint || endpoint.quantity === 0) ? `${svgTag === 'rect' ? 'seat' : svgTag}--disabled` :  `${svgTag === 'rect' ? 'seat' : svgTag}--color-${priceCategories.indexOf(endpoint.price)}`;
    return (
        <>
            {!!endpoint && 
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} data-id={endpoint.id} data-multiple={allowMultipleBuy} className={classNames(`seat ${colorClass}`, {'seat--selected': !!localCartState[endpoint.id]})}/>}
                    {svgTag === 'path' && <path {...{d}} data-id={endpoint.id} data-multiple={allowMultipleBuy} className={classNames(`path ${colorClass}`, {'path--selected': !!localCartState[endpoint.id]})}/>}
                </>
            }
            {!endpoint &&
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} className='seat seat--disabled' />}
                    {svgTag === 'path' && <path {...{d}} className={`path path--disabled`}/>}
                </>
            }
        </>
    )
});

const SeatMap = ({service, toggleEndpoint, localCartState}) => {
    const priceCategories = [];
    const mapName = service.description ? getTagValue(service.description.split(', '), 'seatmap') : '';
    const seatMapName = mapName || 'defaultMap';
    const seatMap = SEAT_MAPS[seatMapName];
    service.endpoints.forEach((item) => {
        const sector = getTagValue(item.tags, 'sector', {separator: '='});
        const row = getTagValue(item.tags, 'row', {separator: '='});
        const seat = getTagValue(item.tags, 'seat', {separator: '='});
        if (sector && row && seat && seatMap.seats[sector] && seatMap.seats[sector][row] && seatMap.seats[sector][row][seat - 1]) {
            seatMap.seats[sector][row][seat - 1].endpoint = {...item};
            if (!priceCategories.includes(item.price)) {
                priceCategories.push(item.price);
            }
        }
    });
    priceCategories.sort((a, b) => b - a);
    const {windowDimensions: {windowWidth}} = useWindowDimensions();
    const initialScale = windowWidth < 768 ? 0.2 : 0.4;
    const [showZoomIcon, setShowZoomIcon] = React.useState(true);
    const seatMapClickHandler = (e) => {
        hideZoomIcon();
        const endpointId = e.target.getAttribute('data-id');
        if (endpointId) {
            const allowMultipleBuy = e.target.getAttribute('data-multiple');
            toggleEndpoint(endpointId, allowMultipleBuy);
        }
        
    };
    const hideZoomIcon = React.useCallback(() => {
        if (showZoomIcon) {
            setShowZoomIcon(false);
        }
    }, [showZoomIcon]);
    return(
        <div className='seat-map'>
            <div className='padding-top-1rem padding-bottom-1rem mw768-padding-bottom-2rem mw768-padding-top-1dot5rem display-grid gap-1rem-1rem price-grid'>
                {priceCategories.map((price, index) =>
                    <div key={index} className='display-flex align-items-center'>
                        <div className={`seat seat--price pointer-events-none seat--color-${index}`}></div>
                        <p className='padding-left-0dot5rem text-md color-gray-200 white-space-nowrap'>{price} ₴</p>
                    </div>
                )}
            </div>
            <div className='seat-map-container'>
                <div className='padding-top-1dot5rem margin-0-auto position-relative seat-map-container__scaling' onClick={seatMapClickHandler}>
                    <TransformWrapper {...{minScale: 0.2, maxScale: 1, centerZoomedOut: true, centerOnInit: true, initialScale, doubleClick: {disabled: true}, onPanning: hideZoomIcon, onPinching: hideZoomIcon, onWheel: hideZoomIcon, onZoom: hideZoomIcon}}>
                        <TransformComponent wrapperClass='seat-map-container__scaling'>
                            <svg  className={classNames(`display-block margin-0-auto malevich-concert-arena-seats position-relative`, {'opacity-0dot5': showZoomIcon})} width={seatMap.width} height={seatMap.height} fill="none" xmlns="http://www.w3.org/2000/svg">
                                {!!seatMap.static && seatMap.static}
                                {
                                    Object.keys(seatMap.seats).map(sectorName =>
                                        Object.keys(seatMap.seats[sectorName]).map((rowName, rowIndex) =>
                                            seatMap.seats[sectorName][rowName].map(({x, y, rx, transform, width, height, endpoint, svgTag, d, allowMultipleBuy}, seatIndex) =>
                                                <Seat key={`${sectorName}-${rowIndex}-${seatIndex}`} {...{endpoint, localCartState, priceCategories, x, y, rx, transform, width, height, svgTag, d, allowMultipleBuy}}/>
                                            )
                                        )
                                    )
                                }
                                {!!seatMap.staticTexts && seatMap.staticTexts}
                            </svg>
                        </TransformComponent>
                    </TransformWrapper>
                    <div className={classNames('seat-map-container__zoom-icon color-gray-600', {'display-none': !showZoomIcon})}>
                        <Expand03Icon className='display-block width-1dot5rem height-1dot5rem'/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default React.memo(SeatMap);