import React from 'react'
import {useLocation, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {useCartDispatch, useCartState} from '../../../contexts/cart';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import {Helmet} from 'react-helmet-async';
import Marked from '../../../components/Marked';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import {adopt} from '../../../math';
import {IN, OUT_NEGATIVE, ENTITY_TYPE, OUT_POSITIVE, COUNTDOWN_CONSTRAINTS, DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';
import ErrorFallback from '../../../components/ErrorFallback';
import Browser from '../../../components/Browser';
import ButtonIcon from '../../../components/button-icon/button-icon.component';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';
import EndpointsHeader, {WarpEndpointsHeader} from './endpoints-header';
import moment from 'moment';
import ServiceSchedule, {SERVICE_DESCRIPTION, ServiceScheduleCard} from './service-schedule';
import {isSet, isTrue} from '../../../utility';
import EndpointSchedule from './endpoint-schedule';
import './basic.scss';
import classNames from 'classnames';
import getTagValue from '../../../utilities/get-tag-value';
import SuspenseImageDynamicSize from '../../../components/SuspenseImageDynamicSize';
import CalendarIcon from '../../../assets/icons0/calendar.svg';
import {format, isBefore} from 'date-fns';
import {getServiceEndDate, getServiceStartDate} from './get-service-date';
import FacebookIcon from '../../../assets/icons0/socials/facebook-icon.svg';
import InstagramIcon from '../../../assets/icons0/socials/instagram-icon.svg';
import TelegramIcon from '../../../assets/icons0/socials/telegram-icon.svg';
import CheckPaymentInfo from './check-payment-info';
import getServiceUrl from '../../../utilities/get-service-url';
import getLocaleQueryParams from '../../../utilities/get-locale-query-params';

const RAZZLE_APP_ORIGIN = process.env.RAZZLE_APP_ORIGIN;

const Endpoint = React.memo(({endpoint, serviceByIdOrSlug, theme, buyAvailable}) => {
    const {locale = 'uk', more} = useQuery();
    const state = useCartState();
    const dispatch = useCartDispatch();
    const [isAnimationRunning, setIsAnimationRunning] = React.useState(false);
    const fEndpoint = useFragment(
        graphql`
            fragment serviceBasicEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                start
                end
                strategy
                every
                description
                descriptionUk
                untie
                hints
                tags
            }
        `,
        endpoint
    );
    const fServiceByIdOrSlug = useFragment(
        graphql`
            fragment serviceBasicEndpoint_serviceByIdOrSlug on Service {
                id
                nameUk
                name
                target
            }
        `,
        serviceByIdOrSlug
    );
    const cartEntry = React.useMemo(() =>
            state.find(e => e.serviceId === fServiceByIdOrSlug.id && e.endpointId === fEndpoint.id),
        [state, fEndpoint.id, fServiceByIdOrSlug.id]
    );
    const now = useNow();
    const dpt = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy,
        fEndpoint.every), [now, fEndpoint]);
    const addEndpoint = () => {
        if(!cartEntry) {
            setIsAnimationRunning(true);
            dispatch({type: 'add', payload: {
                endpointId: fEndpoint.id,
                serviceId: fServiceByIdOrSlug.id,
                ...(fEndpoint.untie && {untie: {price: data.input, currency: 'uah'}}) 
            }});
        }
    };
    const incrementEndpoint = () => {
        dispatch({type: 'increment', payload: cartEntry.key});
    };
    const decrementEndpoint = () => {
        if (cartEntry.quantity > 1) {
            dispatch({type: 'decrement', payload: cartEntry.key});
        } else {
            dispatch({type: 'remove', payload: cartEntry.key});
        }
    };
    const alternativePriceTag = fEndpoint.tags && fEndpoint.tags.length && fEndpoint.tags.find(tag => tag.includes('price'));
    const targetDate = getTagValue(fEndpoint.tags, 'targetDate');
    return (
        <div className='basic-endpoint border-radius-0dot75rem border-1px-solid padding-1dot25rem mw768-padding-2rem'>
            <div className='display-flex flex-direction-column-row'>
                <div className=' mw768-flex-1 mw768-margin-right-1rem'>
                    <div className='basic-endpoint__title text-lg medium mw768-text-xl'>
                        {i18n(fServiceByIdOrSlug, 'name', locale)} | {i18n(fEndpoint, 'name', locale)}
                    </div>
                    {targetDate ? 
                        <div className='basic-endpoint__date text-sm mw768-text-md padding-top-0dot5rem'>{targetDate}</div>
                        :
                        fServiceByIdOrSlug.target && moment(fServiceByIdOrSlug.target).isValid() && <div className='basic-endpoint__date text-sm mw768-text-md padding-top-0dot5rem'>{moment(fServiceByIdOrSlug.target).format('DD.MM.YYYY')}</div>
                    }
                    {((dpt.state === IN && fEndpoint.quantity < 16) || isTrue(more)) && <div className='padding-top-0dot5rem color-warning-500 text-sm'><FormattedMessage defaultMessage='Left'/> {fEndpoint.quantity}</div>}
                    <EndpointSchedule {...{
                        start: fEndpoint.start,
                        end: fEndpoint.end,
                        strategy: fEndpoint.strategy,
                        every: fEndpoint.every,
                        theme
                    }}/>
                </div>
                {dpt.state === IN &&
                    <div className='display-flex mw768-flex-1 justify-content-space-between align-items-center mw768-align-self-center mw768-justify-content-flex-end padding-top-1rem mw768-padding-top-0'>
                        <p className='basic-endpoint__price mw768-margin-right-2dot5rem'>{fEndpoint.price} UAH {alternativePriceTag ? `(${alternativePriceTag.replace('price=', '')})` : ''}</p>
                        <div className='width-7rem min-height-2dot625rem'>
                            {fEndpoint.quantity > 0 &&
                                <Browser>
                                    <div className={classNames('basic-endpoint__btn border-1px-solid padding-0dot5rem border-radius-0dot5rem text-md medium display-flex position-relative justify-content-center shadow-xs', {'basic-endpoint__btn--empty cursor-pointer width-100percent buy-btn-animation--backwards': !cartEntry, 'basic-endpoint__btn--entry buy-btn-animation--forward': !!cartEntry, 'buy-btn-animation--play': isAnimationRunning, 'basic-endpoint__btn--disabled': !buyAvailable})} onClick={() => addEndpoint()}>
                                        <span className={classNames({'add-to-cart--visible': !cartEntry, 'add-to-cart--hidden': !!cartEntry})}><FormattedMessage defaultMessage='Buy'/></span>
                                        <div className={classNames({'decrement-endpoint--visible': !!cartEntry, 'decrement-endpoint--hidden': !cartEntry})}>
                                            <ButtonIcon {...{clickHandler: () => decrementEndpoint()}}>
                                                <CartMinusCircle className={classNames('width-100percent')}/>
                                            </ButtonIcon>
                                        </div>
                                        <p className={classNames('text-md text-align-center', {'endpoint-quantity--visible': !!cartEntry, 'endpoint-quantity--hidden': !cartEntry, 'color-gray-100': theme === 'dark', 'color-gray-700': theme === 'light'})}>{cartEntry ? cartEntry.quantity : ''}</p>
                                        <div className={classNames({'increment-endpoint--visible': !!cartEntry, 'increment-endpoint--hidden': !cartEntry})}>
                                            <ButtonIcon {...{clickHandler: () => incrementEndpoint()}}>
                                                <CartAddCircle className={classNames('width-100percent')}/>
                                            </ButtonIcon>
                                        </div>
                                    </div>
                                </Browser>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

const EndpointWithScheduleContainer = React.memo(({endpoint, children, now, endpoints}) => {
    const {more} = useQuery();
    const endpointAlwaysVisible = getTagValue(endpoint.tags, 'visibility', {defaultValue: 'hidden'}) === 'visible';
    const isWave = getTagValue(endpoint.tags, 'sellAfter') === 'endpoint';
    const sellEndpointId = getTagValue(endpoint.tags, 'sellEndpointId');
    const previousWave = isWave && sellEndpointId && endpoints.find(e => e.id === sellEndpointId);
    const previousWaveEnded = previousWave && React.useMemo(() => {
        if (isWave && previousWave) {
            if (previousWave.quantity === 0) {
                return true;
            } else {
                const previousWaveEnd = new Date(previousWave.end);
                return isBefore(previousWaveEnd, now);
            }
        }
    }, [isWave, previousWave, now]);
    const visibilityCondition = isWave ? previousWaveEnded && endpoint.quantity > 0 : endpoint.quantity > 0;
    const [showEndpoint, setShowEndpoint] = React.useState(visibilityCondition || endpointAlwaysVisible || more);
    const {state} = React.useMemo(() => adopt(now, endpoint.start, endpoint.end, endpoint.strategy,
        endpoint.every), [now, endpoint]);
    React.useEffect(() => {
        if (state === OUT_NEGATIVE && isSet(endpoint.end) && moment(endpoint.end).add(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units) < moment(now)) {
            setShowEndpoint(more || endpointAlwaysVisible || false);
        } else if (state === OUT_POSITIVE && isSet(endpoint.start) && moment(now) < moment(endpoint.start).subtract(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units)) {
            setShowEndpoint(more || endpointAlwaysVisible || false);
        } else {
            setShowEndpoint(visibilityCondition || endpointAlwaysVisible || more);
        }
    }, [endpoint, state, now]);
    return (
        <>
            {showEndpoint &&
                <div className='padding-bottom-1dot75rem'>
                    {children}
                </div>
            }
        </>
    )
});

const EndpointContainer = React.memo(({endpoint, children, endpoints}) => {
    const {more} = useQuery();
    const endpointAlwaysVisible = getTagValue(endpoint.tags, 'visibility', {defaultValue: 'hidden'}) === 'visible';
    if ((!endpoint.start && !endpoint.end && endpoint.quantity > 0) || endpointAlwaysVisible || more) return (
        <div className='padding-bottom-1dot75rem'>
            {children}
        </div>
    );
    const now = useNow();
    return (
        <EndpointWithScheduleContainer {...{endpoint, now, endpoints}}>
            {children}
        </EndpointWithScheduleContainer>
    );
});

const Service = ({ticketsBoxRef}) => {
    const {idOrSlug} = useParams();
    const location = useLocation();
    const {serviceByIdOrSlug} = useLazyLoadQuery(
        graphql`
            query serviceBasicQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    location
                    start
                    end
                    strategy
                    every
                    target
                    slug
                    warp
                    merchantAccount
                    merchant {
                        id
                    }
                    endpoints {
                        id
                        quantity
                        start
                        end
                        strategy
                        every
                        price
                        tags
                        ...serviceBasicEndpoint_endpoint
                    }
                    ...serviceBasicEndpoint_serviceByIdOrSlug
                }
            }
        `,
        {payload: decodeURIComponent(idOrSlug)}
    );
    const tags = serviceByIdOrSlug.description ? serviceByIdOrSlug.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const theme = getTagValue(tags, 'theme', {defaultValue: 'dark'});
    const posterUrl = getTagValue(tags, 'posterUrl');
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const seoDescription = decodeURI(getTagValue(tags, 'seoDescription', {defaultValue: ''}));
    const seoTitle = decodeURI(getTagValue(tags, 'seoTitle', {defaultValue: ''}));
    const target = serviceByIdOrSlug.target ? format(new Date(serviceByIdOrSlug.target), 'dd-MM-yyyy') : null;
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        serviceByIdOrSlug.endpoints ? serviceByIdOrSlug.endpoints.map(e => e.price).sort() : [];
        if (serviceByIdOrSlug.endpoints && serviceByIdOrSlug.endpoints.length) {
            return serviceByIdOrSlug.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, serviceByIdOrSlug.endpoints[0].price);
        }
    }, [serviceByIdOrSlug]);
    const locationName = serviceByIdOrSlug.location;
    const facebook = getTagValue(tags, 'facebook');
    const instagram = getTagValue(tags, 'instagram');
    const telegram = getTagValue(tags, 'telegram');
    const showTicketsPrice = isTrue(getTagValue(tags, 'showTicketsPrice', {defaultValue: 'false'}));
    const showEventLocation = isTrue(getTagValue(tags, 'showEventLocation', {defaultValue: 'false'}));
    const {locale = 'uk'} = useQuery();
    const now = useNow();
    const serviceStart = getServiceStartDate(serviceByIdOrSlug.endpoints);
    const serviceEnd = getServiceEndDate(serviceByIdOrSlug.endpoints, serviceByIdOrSlug);
    const {state} = React.useMemo(() => adopt(now, serviceStart, serviceEnd, serviceByIdOrSlug.strategy,
        serviceByIdOrSlug.every), [now, serviceByIdOrSlug]);
    const [buyAvailable, setBuyAvailable] = React.useState(true);
    const defaultDescription = `Придбати квиток на ⭐️ ${i18n(serviceByIdOrSlug, 'name', locale)}${target ? ` ☝${target}` : ''}${lowestTicketPrice ? `. Від ${lowestTicketPrice} грн` : ''} | Купити квитки онлайн`;
    const serviceUrl = getServiceUrl({description: serviceByIdOrSlug.description, id: serviceByIdOrSlug.id, slug: serviceByIdOrSlug.slug, warp: serviceByIdOrSlug.warp, location: {search: ''}});
    return (
        <>
        <Helmet>
            <title>{seoTitle || `${i18n(serviceByIdOrSlug, 'name', locale)}: Купити квитки на ottry.com`}</title>
            <meta name='description' content={seoDescription || defaultDescription}/>
            {!!posterUrl && <meta property='og:image' content={`https://${posterUrl}`}/>}
            <link rel='canonical' href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams('', locale)}`}/>
            <link href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams(location.search, 'uk')}`} rel='alternate' hreflang="uk"/>
            <link href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams(location.search, 'en')}`} rel='alternate' hreflang="en"/>
        </Helmet>
        <div>
            <div className={`view theme theme--${theme}`}>
                {posterUrl &&
                    <div className=''>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageDynamicSize
                                className='basic__poster-img display-block max-height-35rem mw768-max-height-37dot5rem margin-0-auto max-width-100vw mw768-max-width-76rem'
                                src={`https://${posterUrl}`}
                            />
                        </ErrorBoundary>
                    </div>
                }
                <div className='block'>
                    <div className='max-width-50rem margin-0-auto'>
                        <h1 className='basic__title display-sm semibold padding-top-2rem mw768-padding-top-4rem'>
                            {i18n(serviceByIdOrSlug, 'name', locale)}
                        </h1>
                        {target &&
                            <div className='padding-top-0dot5rem basic__target display-flex'>
                                <CalendarIcon className='display-block height-1dot25rem width-1dot25rem'/>
                                <p className='text-md medium padding-left-0dot5rem'>{target}</p>
                                {targetEnd && target !== targetEnd &&
                                    <span className='text-md medium padding-left-0dot25rem'>{` — ${targetEnd}`}</span>
                                }
                            </div>
                        }
                        <div className='padding-top-1dot5rem'>
                            <Marked {...{content: i18n(serviceByIdOrSlug, 'details', locale)}}/>
                        </div>
                        {serviceByIdOrSlug.endpoints && serviceByIdOrSlug.endpoints.length && showTicketsPrice && 
                            <p className='marked text-md'>
                                <span className='bold'><FormattedMessage defaultMessage='Price: from'/> </span>
                                <span>{lowestTicketPrice} UAH</span>
                            </p>
                        }
                        {showEventLocation && 
                            <p className='marked text-md'>
                                <span className='bold'><FormattedMessage defaultMessage='Location'/>: </span>
                                <span>{ locationName ? decodeURI(locationName) : <FormattedMessage defaultMessage='To be announced'/>}</span>
                            </p>
                        }
                        {(facebook || instagram || telegram) &&
                            <div className='padding-top-1dot25rem mw768-padding-top-3rem display-flex gap-1dot25rem'>
                                {facebook &&
                                    <a href={facebook} target='_blank' rel='noopener noreferrer'>
                                        <FacebookIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {instagram &&
                                    <a href={instagram} target='_blank' rel='noopener noreferrer'>
                                        <InstagramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {telegram &&
                                    <a href={telegram} target='_blank' rel='noopener noreferrer'>
                                        <TelegramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                            </div>
                        }
                        <div className='margin-top-1rem mw768-margin-top-3rem padding-top-2rem padding-bottom-2rem' ref={ticketsBoxRef}>
                            <WarpEndpointsHeader {...{serviceByIdOrSlug: serviceByIdOrSlug, theme}}>
                                {({decrementEndpoint, incrementEndpoint, localCartState, checkout}) =>
                                    <EndpointsHeader {...{serviceByIdOrSlug: serviceByIdOrSlug, decrementEndpoint, incrementEndpoint, localCartState, checkout, showCart: state !== OUT_NEGATIVE, theme}}/>
                                }
                            </WarpEndpointsHeader>
                            <div className='padding-top-2rem'>
                                <div>
                                    {i18n(serviceByIdOrSlug, 'details', locale) && i18n(serviceByIdOrSlug, 'details', locale).endsWith('!') ?
                                        <div className='padding-bottom-2rem'>
                                            <ServiceScheduleCard {...{...SERVICE_DESCRIPTION[OUT_NEGATIVE].default, theme}}/>
                                        </div>
                                        :
                                        <>
                                            <ServiceSchedule {...{
                                                start: serviceStart,
                                                end: serviceEnd,
                                                strategy: serviceByIdOrSlug.strategy,
                                                every: serviceByIdOrSlug.every,
                                                state,
                                                theme
                                            }}/>
                                            {state === IN &&
                                                <div>
                                                    {serviceByIdOrSlug.endpoints.map((endpoint) =>
                                                        <EndpointContainer key={endpoint.id} {...{endpoint, endpoints: serviceByIdOrSlug.endpoints}}>
                                                            <Endpoint {...{endpoint, serviceByIdOrSlug: serviceByIdOrSlug, theme, buyAvailable}}/>
                                                        </EndpointContainer>
                                                    )}
                                                    {!serviceByIdOrSlug.endpoints.some(e => e.quantity) && <ServiceScheduleCard {...{...SERVICE_DESCRIPTION[OUT_NEGATIVE].default, theme}}/>}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {state === IN && serviceByIdOrSlug.endpoints.some(e => e.quantity) && <p className='text-dm mw768-text-md color-gray-400 padding-top-0dot25rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>}
                        </div>
                    </div>
                </div>
            </div>
            <CheckPaymentInfo {...{serviceByIdOrSlug: serviceByIdOrSlug, setBuyAvailable}}/>
        </div>
        </>
    )
};

export default React.memo(Service);